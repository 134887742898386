export default Object.freeze({
  AI_GROUP: 'aigroup',
  DUITNOW: 'duitnow',
  FPX: 'fpx',
  GO_BIZ: 'gobiz',
  GO_BIZ_UPP: 'gobiz-upp',
  ROSE_ENGINE: 'rosengine',
  M1PAY: 'm1pay',
  SENANG_PAY: 'senangpay',
  STRIPE: 'stripe',
  ISERVE: 'iserve',
});
